<template>
    <div class="ListConpentsBox">
        <van-empty 
            class="custom-image"
            :image="require('@/assets/Image/custom-empty-image.png')" 
            v-if="list.length <= 0"
         />
        <van-list
            ref="List"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check='false'
            >
            <div class="EventBox van-hairline--bottom" v-for="(item,index) in list" :key="index" :title="item"  @click="Details(item)">
               
                <div class="BoxEvent">
                    <div class="RowtIitle"> 
                        <strong><van-icon name="invition" /></strong>{{item.DA_ProjectName}}		
                        <!-- <span class="StateBox" :class="{acitve:item%2 == 1 }">{{ item%2 == 1 ?'可预约':'不可预约'}}</span>		 -->
                    </div>
                     <div class="RowtIitle"> 
                        <p><strong><van-icon name="invition" /></strong>{{item.DA_No}}</p>
                        <p><strong><van-icon name="underway" /></strong>{{item.DA_ApplicationTime}}</p>
                    </div>
                    <div class="RowtIitle"> 
                        <p><strong><van-icon name="friends" /></strong>{{item.DA_UserName}}</p>
                        <p><strong><van-icon name="phone-circle" /></strong>{{item.DA_UserPhone}}</p>
                    </div>
                   
                    <div class="RowtIitle"><strong><van-icon name="todo-list" /></strong>{{item.DA_Info}}</div>
                </div>
            </div>
        
        </van-list>
        
    </div>
</template>
<script>
import { NavBar,Tab, Tabs ,List, Image as VanImage ,Icon ,Empty  } from 'vant';
import { Api_YyMAADangerousApplication_GetPage } from "@/api/danger";
export default {
    components:{
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [VanImage.name]: VanImage,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
    },
    props:{
        DA_AuditStatus:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            list:[],
            loading: false,
            finished: false,  
            pageindex:0,
        }
    },
    methods:{
        // 触发滚动检查
        CheckList(){
            this.list = [];
            this.finished = false;
            this.$nextTick(()=>{
                this.$refs.List.check();
            })
        },
        GetList(){
            Api_YyMAADangerousApplication_GetPage({
                pageindex:this.pageindex,
                pagesize:6,
                DA_AuditStatus:this.DA_AuditStatus
            }).then(res=>{
                this.loading = false;
       
                this.list = this.list.concat(res.List)
                if(this.list.length >= res.AllConut){
                    this.finished = true
                }
            })
        },
        Details(item){
            this.$router.push({path:`/dangerEdit/${item.DA_ID}`})
        },
        onLoad() {
            this.loading = true;
            this.pageindex++
			this.GetList()
		},
    }
}
</script>
<style lang="scss" scoped>
.EventBox {
    padding: 3px 0px;
    background: #fff;
    display: flex;
    flex-direction: row;
    .BoxEvent {
        flex: 1;
        padding:6px 5px;
        padding-left: 8px;
        font-size: 13px;
        color: #333;
        .RowtIitle {
            line-height: 25px;
            display: flex;
            flex-direction: row;
            position: relative;
            strong {
                padding-right: 3px;
                padding-right: 4px;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                align-items: center;
                i {
                    font-size: 18px;
                    color: #ff9254;
                }
            }
            p:first-child {
                width: 48%;
            }
            p:last-child {
                flex: 1;
            }
            p {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .StateBox {
                position: absolute;
                top: 0px;
                right: 0px;
                color:#07c160;
            }
            .acitve {
                color: #ee0a24;
            }
        }
    }
}
 .custom-image {
        /deep/ .van-empty__image {
            width: 90px;
            height: 90px;
        }
    }
</style>